import React, {useEffect} from 'react'
import { useSearchParams  } from 'react-router-dom';
import axios from "axios";
import jwt_decode from "jwt-decode";

const CallbackBacklog = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const getAccessToken = async() => {
            const params = {
                grant_type: 'authorization_code',
                code: searchParams.get('code'),
                redirect_uri: process.env.REACT_APP_CALLBACK_URL,
                client_id: process.env.REACT_APP_CLIENT_BACKLOG,
                client_secret: process.env.REACT_APP_CLIENT_SECRET_BACKLOG
            }
            const { data } = await axios.post(`https://relipa.backlog.com/api/v2/oauth2/token`, params)
            localStorage.setItem('access_token', data.access_token);
            window.location.href = '/'
        };

        getAccessToken && getAccessToken();
    }, [])

    return (
        <>{searchParams.get('code')}</>
    );
}

export default CallbackBacklog;
