import { useForm } from "react-hook-form";
import React from "react";

function LoginPage(props) {
  const { handleSubmit, register } = useForm();

  const onSubmit = async () => {
    const params = {
      client_id: process.env.REACT_APP_CLIENT_BACKLOG,
      redirect_url: process.env.REACT_APP_CALLBACK_URL,
      response_type: process.env.REACT_APP_RESPONSE_TYPE,
      scope: process.env.REACT_APP_SCOPE
    }
    window.location.href = `${process.env.REACT_APP_OAUTH_URL}?${new URLSearchParams(params).toString()}`
  };
    return (
    <div className="App">
      <div style={{display: 'flex', justifyContent: 'center'}}  className="container">
        <div style={{marginTop: "100px", display: 'flex', justifyContent: 'center'}} className="screen">
          <div className="screen__content">
            <form onSubmit={handleSubmit(onSubmit)} className="login">
              {/*<div className="login__field">
                <i className="login__icon fas fa-user"></i>
                <input
                  type="text"
                  className="login__input"
                  placeholder="your apikey"
                  {...register("apikey")}
                />
              </div>*/}
              <button className="button login__submit">
                <span className="button__text">Log In with backlog</span>
                <i className="button__icon fas fa-chevron-right"></i>
              </button>
            </form>
            <div className="social-login">
            </div>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4"></span>
            <span className="screen__background__shape screen__background__shape3"></span>
            <span className="screen__background__shape screen__background__shape2"></span>
            <span className="screen__background__shape screen__background__shape1"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
