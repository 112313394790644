import React from 'react'
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import LoginPage from "./Login/LoginPage";
import App from "./Dashboard/Dashboard";
import CallbackBacklog from "./components/callback";
import Projects from "./components/projects/Projects"

const browserHistory = createBrowserHistory()

export const appRouter = [
  {
    name: 'Dashboard',
    path: '/',
    component: App,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false
    }
  },
  {
    name: 'Project',
    path: '/project',
    component: Projects,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: true,
      hidden: false,
      child: false
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: LoginPage,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: false,
      hidden: true,
      child: false
    }
  },
  {
    name: 'Callback',
    path: '/callback',
    component: CallbackBacklog,
    icon: 'fa fa-diamond',
    meta: {
      role: '*',
      isPrivate: false,
      hidden: true,
      child: false
    }
  },
]
const renderRouter = () => {
  const accessToken = localStorage.getItem('access_token');
  const whitelist = ['/login', 'callback',];

  return appRouter.map((render, index) => {
    const Componet = render.component;
    if (accessToken && whitelist.indexOf(render.path) >= 0) {
      return <Route key={index} path={render.path} element={<Navigate to="/" replace={true} key={render.name} />} />
    }
    if (render.meta.isPrivate && !accessToken && whitelist.indexOf(render.path) < 0) {
      return <Route key={index} path={render.path} element={<Navigate to="/login" replace={true} key={render.name} />} />
    }
    return <Route key={index} exact path={render.path} element={<Componet />} />
  })
};

const routes = () => {
  return (
    <div className={`main-content`}>
      <Router history={browserHistory}>
        <Routes>
          {renderRouter()}
        </Routes>
      </Router>
    </div>
  )
}

export default routes
