import React, { useEffect, useState } from "react";
import {
  Tabs,
  Layout,
  Menu,
  Popconfirm,
  Select,
  Form,
  message
} from "antd";
import { getCountIssueAPI } from "../utils/api_call/issue.api";
import NewReport from "../components/NewReport/NewReport";
import {
  fomatDataKey,
  getConfig,
} from "../utils/api_call/const";
import UploadXlsx from "../UploadXlsx/UploadXlsx";
import "./Dashboard.scss";
import axios from "axios";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { CODE_403 } from "../utils/constantVar";

function Dashboard(props) {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [total, setTotal] = useState();
  const [collapsed, setCollapsed] = useState(true);
  const [user, setUser] = useState({});
  const [projects, setProjects] = useState([]);
  const [IssueTypeId, setIssueTypeId] = useState(null);
  const [selectproject, setSelectproject] = useState(null);
  const [count, setCount] = useState(0);
  const [projectId, setProjectsId] = useState(0)
  const [typeList, setTypeList] = useState([])
  const [milestones, setMileStones] = useState([])
  const [categories, setCategories] = useState([])
  const { TabPane } = Tabs;
  const { Option } = Select;
  const { Header, Sider, Content } = Layout;
  const [memberOfProject, setMemberOfProject] = useState([]);

  // const [messageApi, contextHolder] = message.useMessage()


  useEffect(() => {
    // getListProject();
    getProjects();
    // getListUser();
    getUser()
  }, []);

  useEffect(() => {
    if (!!projectId) {
      getMileStrone()
      getCategory()
    }
  }, [projectId])

  // useEffect(() => {
  //   selectproject && init();
  // }, [filter]);

  const getProjects = async () => {
    try {
      const { data } = await axios(getConfig("get", "api/v2/projects"));
      setProjects(data);
    } catch (error) {
      console.log(error.response.data.errors[0], "project");
      let err = error.response.data.errors[0].message;
      if (
        err === "The access token expired." ||
        err === "Authentication failure."
      ) {
        localStorage.removeItem("access_token");
        window.location.href = "/";
      }
    }

    // let access_token = localStorage.getItem("access_token");
    // axios.get('https://relipa.backlog.com/api/v2/projects',
    //   {
    //     headers: {
    //       'Authorization': `Bearer ${access_token}`
    //     },
    //   }
    // ).then(res => console.log(res.data, "-----"))
    // .error(err => {
    //   if(err.message === "The access token expired.") {
    //     localStorage.removeItem('access_token')
    //     window.location.href = '/'
    //   }

    // })
  };

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };

  const getUser = () => {
    axios
      .get(`https://relipa.backlog.com/api/v2/users/myself`, config)
      .then(function (res) {
        setUser(res.data)
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getMileStrone = async () => {
    const { data } = await axios(getConfig('get', `api/v2/projects/${projectId}/versions`))
    setMileStones(data)
  }

  const getCategory = async () => {
    const { data } = await axios(getConfig('get', `api/v2/projects/${projectId}/categories`))
    setCategories(data)
  }

  const getProjectUserList = async (projectId) => {
    const { data } = await axios(
      getConfig("get", `api/v2/projects/${projectId}/users`)
    );
    setMemberOfProject(data)

  };

  const getIssueTypes = async (projectId) => {
    const { data } = await axios(
      getConfig("get", `api/v2/projects/${projectId}/issueTypes`)
    );

    setTypeList(data)

    const taskID = data.find((item) => {
      if (item.name === "Task") {
        return item;
      }
    });
    setIssueTypeId(taskID.id);
  };

  function callback(key) { }

  async function init(projectId) {
    setSelectproject(projectId);

    Promise.all([
      axios(
        getConfig(
          "get",
          `api/v2/issues?${filter}count=100&projectId[]=${projectId}`
        )
      ),
      getCountIssueAPI(),
    ])
      .then(function (response) {
        const newData = fomatDataKey(response[0].data);
        setData(newData);
        setTotal(response[1].data.count);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // function handleFilter(valueFilter) {
  //   let param = "";
  //   if (valueFilter.Status) {
  //     param += `statusId[]=${valueFilter.Status}&`;
  //   }

  //   if (valueFilter.Priority) {
  //     param += `priorityId[]=${valueFilter.Priority}&`;
  //   }

  //   if (valueFilter.Milstone) {
  //     param += `milestoneId[]=${valueFilter.Milstone}&`;
  //   }

  //   if (valueFilter.IssueType) {
  //     param += `issueTypeId[]=${valueFilter.IssueType}&`;
  //   }

  //   if (valueFilter.Assignee) {
  //     param += `assigneeId[]=${valueFilter.Assignee}&`;
  //   }

  //   if (valueFilter.Category) {
  //     param += `categoryId[]=${valueFilter.Category}&`;
  //   }

  //   if (valueFilter.updatedSince) {
  //     param += `updatedSince=${valueFilter.updatedSince}&`;
  //   }

  //   if (valueFilter.updatedUntil) {
  //     param += `updatedUntil=${valueFilter.updatedUntil}&`;
  //   }

  //   setFilter(param);
  // }

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    window.location.href = "/";
  };

  const handleChangeProject = (id) => {
    setProjectsId(id)
    setCount(count + 1)
    getIssueTypes(id);
    getProjectUserList(id)
    init(id);
  };

  return (
    <div className="dashboard">
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo" />
          <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
            {/* <Menu.Item key="1" icon={<UserOutlined />}>
              nav 1
            </Menu.Item>
            <Menu.Item key="2" icon={<UploadOutlined />}>
              nav 2
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Layout className="site-layout">

          <Header className="site-layout-background">

            {collapsed ? (
              <MenuUnfoldOutlined />
            ) : (
              <MenuFoldOutlined onClick={() => toggle()} />
            )}
            {user && (<h3 style={{ position: 'relative', top: '-25px', fontWeight: 'bold' }}>{user?.name}</h3>)}
            <Popconfirm
              placement="bottom"
              title={"Bạn có muốn đăng xuất tài khoản?"}
              onConfirm={logout}
              okText="Yes"
              cancelText="No"
            >
              <LogoutOutlined />
            </Popconfirm>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 820,
              display: "block",
            }}
          >
            <Form.Item
              label="Chọn Project của bạn"
              rules={[{ required: true }]}
            >
              {/* <AutoComplete
                options={options}
                style={{ width: 200 }}
                onSelect={onSelect}
                onSearch={onSearch}
                placeholder="Nhập tên dự án"
              /> */}
              {!!projects.length && (
                <Select
                  defaultValue={projects[0].name}
                  style={{ marginBottom: 20, width: "400px" }}
                  onChange={handleChangeProject}
                >
                  {projects.map((item, index) => (
                    <Option value={item.id} key={index}>{item.name}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            {selectproject && (
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Tạo mới, chỉnh sửa Issues" key="1">
                  <UploadXlsx
                    IssueTypeList={typeList}
                    IssueTypeId={IssueTypeId}
                    projectId={selectproject}
                    // listUser={listUser}
                    milestones={milestones}
                    categories={categories}
                    count={count}
                    memberOfProject={memberOfProject}
                  />
                </TabPane>
                <TabPane tab="Báo cáo" key="2">
                  {/* <Report /> */}
                  <NewReport count={count} />
                </TabPane>
              </Tabs>
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
export default Dashboard;
