import "antd/dist/antd.css";
import "./App.css";
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { getListIssueAPI } from "./utils/api_call/issue.api";
import routes from './routes';

const axios = require("axios");


function App() {
  const { handleSubmit, register } = useForm();
  return (
    <div className="App">
      { routes() }
    </div>
  );
}
export default App;
