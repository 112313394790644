import axios from 'axios';
import moment from 'moment'

export const fomatDataKey = (arr) => {
    const result = arr.map((item, index) => {
        item.key = index + 1;
        return item;
    });
    return result;
};

export const ExcelDateToJSDateData = (dateNumber) => {
    if(!dateNumber){
        return ''
    }

    let myDate = null
    let result = dateNumber
    if(!isNaN(dateNumber)) {
        myDate = handleconventDate(dateNumber)
        result = moment(myDate).format("YYYY-MM-DD");
    } else {
        let date = moment(dateNumber).format("YYYY-MM-DD");
        result = date
    }
    
    
    return result
}



function handleconventDate(serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}
export const getConfig = (method, url) => {
    let access_token = localStorage.getItem('access_token')
    return {
        method: method,
        url: `https://relipa.backlog.com/${url}`,
        headers: {
            'Authorization': `Bearer ${access_token}`
        },
    };
}

export const promotionClient = axios.create({
    baseURL: `http://relipa.backlog.com`,
});

const requestInterceptor = (request) => {
    var accessToken = localStorage.getItem('access_token');;
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.ContentType = "application/json"
    request.timeout = 5000;
    return request;
};


const responseSuccessInterceptor = (response) => {
    return response;
};

const handleResponseError = (err) => {
    console.error(err)
}
const responseErrorInterceptor = (error) => {
    handleResponseError(error);
    return Promise.reject(error);
};

const clients = [promotionClient];

clients.forEach(client => {
    client.interceptors.request.use(requestInterceptor);
    client.interceptors.response.use(
        responseSuccessInterceptor,
        responseErrorInterceptor
    );
});

export const listUser = [
    {
        "id": 318560,
        "userId": null,
        "name": "anhntn",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "anhntn@relipasoft.com",
        "nulabAccount": {
            "nulabId": "HpsOjXlOELpJeFlrIH4mhGQgEvVaEpSK3VjjzUNPFGykIv0QQ3",
            "name": "anhntn",
            "uniqueId": "anhntn-97q"
        },
        "keyword": "anhntn anhntn"
    },
    {
        "id": 322156,
        "userId": null,
        "name": "AnhNV",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "anhnv@relipasoft.com",
        "nulabAccount": {
            "nulabId": "uCcoTCfMFRuzWDfWkLAVFAWGLvLxU7PWeG3aNK4vSkjhjSz10o",
            "name": "AnhNV",
            "uniqueId": "anhnv-ov5"
        },
        "keyword": "AnhNV AnhNV"
    },
    {
        "id": 318549,
        "userId": null,
        "name": "ngocbd",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "ngocbq@relipasoft.com",
        "nulabAccount": {
            "nulabId": "nkSUENDe69oOOKAjkrJpbJA5qtSjZZFXXD8cGX9ms2F6ThCKma",
            "name": "BuiNgoc",
            "uniqueId": "ngocbq-EP6"
        },
        "keyword": "BuiNgoc BuiNgoc"
    },
    {
        "id": 318550,
        "userId": null,
        "name": "nhungntc",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "nhungntc@relipasoft.com",
        "nulabAccount": {
            "nulabId": "8NfuOKiTYDnAB8uJxpBZJ27oZxAqZMi5ou5yohlV43Stxoc0iK",
            "name": "Nguyễn Thị Cẩm Nhung",
            "uniqueId": "nhungntc"
        },
        "keyword": "Cẩm Nhung  Cẩm Nhung"
    },
    {
        "id": 318574,
        "userId": null,
        "name": "datdq",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "datdq@relipasoft.com",
        "nulabAccount": {
            "nulabId": "WClCGLreXB554WzpxgyY2mcT8F1SdQ5fziL7F716NCuJUIRdK9",
            "name": "datdq",
            "uniqueId": "datdq-Lmw"
        },
        "keyword": "datdq datdq"
    },
    {
        "id": 318526,
        "userId": null,
        "name": "datnv",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "datnv@relipasoft.com",
        "nulabAccount": {
            "nulabId": "2U6d1C3LWgKbTx9pHw23869F6w14b98O4tD3KLSKm93uv0L6up",
            "name": "datnv",
            "uniqueId": "datnv-8P6"
        },
        "keyword": "datnv datnv"
    },
    {
        "id": 322285,
        "userId": null,
        "name": "huandd",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "huandd@relipasoft.com",
        "nulabAccount": {
            "nulabId": "yCnU42JhexQFGRSkrupsYaU6s5LCeeAQDDMFE1yAMXukP55ngT",
            "name": "Dinh Huan",
            "uniqueId": "huandd-AXa"
        },
        "keyword": "Dinh Huan Dinh Huan"
    },
    {
        "id": 318523,
        "userId": null,
        "name": "sangdx",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "sangdx@relipasoft.com",
        "nulabAccount": {
            "nulabId": "a8zSZnAB418D5gZmF32xIGK264jQTgmjWNriBqU8ODdl2bn76m",
            "name": "Do Xuan Sang",
            "uniqueId": "sangdx"
        },
        "keyword": "Do Xuan Sang Do Xuan Sang"
    },
    {
        "id": 320829,
        "userId": null,
        "name": "hadn",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "hadn@relipasoft.com",
        "nulabAccount": {
            "nulabId": "iMhvEPKCkmxD9HKSBFLslh3CaGnfotxRgASai4c0xzk3Ky4psz",
            "name": "Doan Nhat Ha",
            "uniqueId": "hadn"
        },
        "keyword": "Doan Nhat Ha Doan Nhat Ha"
    },
    {
        "id": 318567,
        "userId": null,
        "name": "Dương Mạnh Hoàng",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "hoangdm@relipasoft.com",
        "nulabAccount": {
            "nulabId": "DF6aA6p6ERjXwRgOaNVOPBHFlg0z3QgkFvhOG3qAUdAodbdUmq",
            "name": "Dương Mạnh Hoàng",
            "uniqueId": "hoangdm-ivg"
        },
        "keyword": "Dương Mạnh Hoàng Dương Mạnh Hoàng"
    },
    {
        "id": 318563,
        "userId": null,
        "name": "duyn",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "duyn@relipasoft.com",
        "nulabAccount": {
            "nulabId": "U6SY3NB4qPd7FcoHAbqaREiih1gExfsd9FWivBd91kBczlGjZM",
            "name": "duyn",
            "uniqueId": "duyn-sM1"
        },
        "keyword": "duyn duyn"
    },
    {
        "id": 318568,
        "userId": null,
        "name": "Đào Hữu Nghĩa",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "nghiadh@relipasoft.com",
        "nulabAccount": {
            "nulabId": "fQIKcDGYg7nQups2JWCa6HUsSYEilJNC6w39bsjDUUa38yTtOe",
            "name": "Đào Hữu Nghĩa",
            "uniqueId": "nghiadh"
        },
        "keyword": "Đào Hữu Nghĩa Đào Hữu Nghĩa"
    },
    {
        "id": 318530,
        "userId": null,
        "name": "haidn",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "haidn@relipasoft.com",
        "nulabAccount": {
            "nulabId": "t17gOf0vIV2efpcilSmdhMRTnN5mViMCoCvK3wY74JY67lVxOU",
            "name": "Đỗ Nam Hải",
            "uniqueId": "haidn-oUO"
        },
        "keyword": "Đỗ Nam Hải Đỗ Nam Hải"
    },
    {
        "id": 318516,
        "userId": null,
        "name": "GiangNK(ザン)",
        "roleType": 1,
        "lang": "en",
        "mailAddress": "giang@relipasoft.com",
        "nulabAccount": {
            "nulabId": "qDheJhwM7ob7GZtaXiw0HfKWJ70MRjGPwc3ze47cORdvYzKJys",
            "name": "GiangNK(ザン)",
            "uniqueId": "giangnk"
        },
        "keyword": "GiangNK(ザン) GiangNK(ZAN)"
    },
    {
        "id": 318562,
        "userId": null,
        "name": "Hạ Đức Phúc",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "phuchd@relipasoft.com",
        "nulabAccount": {
            "nulabId": "nncOAzP7BtIJfC0Q7JmbdmEVSp7DAfwodia0vLnhiLpZQpA48w",
            "name": "Hạ Đức Phúc",
            "uniqueId": "phuchd-Les"
        },
        "keyword": "Hạ Đức Phúc Hạ Đức Phúc"
    },
    {
        "id": 318520,
        "userId": null,
        "name": "handn",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "handn@relipasoft.com",
        "nulabAccount": {
            "nulabId": "MNpbMWBj0eNwIQFDVlguXJRYm63A2s7NOpQ1n407HnGcayVtiB",
            "name": "handn",
            "uniqueId": "handn"
        },
        "keyword": "handn handn"
    },
    {
        "id": 318564,
        "userId": null,
        "name": "Hoang Ngoc Khoi",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "khoihn@relipasoft.com",
        "nulabAccount": {
            "nulabId": "8f8deJgEPRs9wrwJTUheaQdlSKixfFkoEA5GbSKPzDr3w3KM4c",
            "name": "Hoang Ngoc Khoi",
            "uniqueId": "khoihn"
        },
        "keyword": "Hoang Ngoc Khoi Hoang Ngoc Khoi"
    },
    {
        "id": 318554,
        "userId": null,
        "name": "Huỳnh Thị Thu Thảo",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "thaohtt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "bpvXwtAumnaLJSSSpuFh63kfY8P3y2nyIU8ImHzfoNXS8Ufboo",
            "name": "Huỳnh Thị Thu Thảo",
            "uniqueId": "thaohtt"
        },
        "keyword": "Huỳnh Thị Thu Thảo Huỳnh Thị Thu Thảo"
    },
    {
        "id": 318514,
        "userId": null,
        "name": "huypq",
        "roleType": 1,
        "lang": "en",
        "mailAddress": "huypq@relipasoft.com",
        "nulabAccount": {
            "nulabId": "fsPLDsCeuP1BS6eXgSKkKzMiNDGseIcdyM49QsIHRscns1Cqvd",
            "name": "Huy Phung",
            "uniqueId": "huypqx"
        },
        "keyword": "huypq huypq"
    },
    {
        "id": 318571,
        "userId": null,
        "name": "HuyTQ",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "huytq@relipasoft.com",
        "nulabAccount": {
            "nulabId": "jsmChZpv8V9EIzoLyptnVt8zywlpTHvHT7ZXDi2oQwB0cBl9d0",
            "name": "HuyTQ",
            "uniqueId": "huytq-BHt"
        },
        "keyword": "HuyTQ HuyTQ"
    },
    {
        "id": 318565,
        "userId": null,
        "name": "lamln",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "lamln@relipasoft.com",
        "nulabAccount": {
            "nulabId": "BIslnJ7xQeaA7dhsQNOLxtSCIjs8eAy34XNQh6fXO88LitRMAK",
            "name": "lamln",
            "uniqueId": "lamln"
        },
        "keyword": "lamln lamln"
    },
    {
        "id": 318531,
        "userId": null,
        "name": "LinhBK",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "linhbk@relipasoft.com",
        "nulabAccount": {
            "nulabId": "LCaGo1C4tsSUZezvcwrtbX8O6Wmeqda3P4AWv8zY0Bjq7MED1A",
            "name": "LinhBK",
            "uniqueId": "linhbk-7mx"
        },
        "keyword": "LinhBK LinhBK"
    },
    {
        "id": 318559,
        "userId": null,
        "name": "longvt",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "longvt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "JYNqjjF12Ak1HF5osAOFUhGWrQkSyrsR3SqnNcAp822fBmehku",
            "name": "Long Vu",
            "uniqueId": "longvt-f8e"
        },
        "keyword": "Long Vu Long Vu"
    },
    {
        "id": 318553,
        "userId": null,
        "name": "Mai Tran",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "maitt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "MpwUZa2KpzChOMBjuQKt4vIOSTEvEeI9Mnfn0iTxct3de02qzP",
            "name": "Mai Tran",
            "uniqueId": "maitt-vOV"
        },
        "keyword": "Mai Tran Mai Tran"
    },
    {
        "id": 318573,
        "userId": null,
        "name": "Nguyễn Hữu Trí",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "trinh@relipasoft.com",
        "nulabAccount": {
            "nulabId": "VaSudZtWjgqsfa07836h99tbXD5caYJmlFRxLQEa76tfM1lXq1",
            "name": "Nguyễn Hữu Trí",
            "uniqueId": "trinh-Exc"
        },
        "keyword": "Nguyễn Hữu Trí Nguyễn Hữu Trí"
    },
    {
        "id": 318527,
        "userId": null,
        "name": "datnt",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "datnt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "xgy4X6gB6wdsRCndigoYiU3fFn9s21ho3GskRNWN9CqW7hlDhs",
            "name": "Nguyen Thanh Dat",
            "uniqueId": "datnt-Imi"
        },
        "keyword": "Nguyen Thanh Dat Nguyen Thanh Dat"
    },
    {
        "id": 321407,
        "userId": null,
        "name": "anhnt2",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "anhnt2@relipasoft.com",
        "nulabAccount": {
            "nulabId": "JJHmr8y0eGbhF38cNEqTxMeL1VnLoojiSX1UFO0fPBV5GEONt3",
            "name": "Nguyen Thi Anh",
            "uniqueId": "anhnt2-WYr"
        },
        "keyword": "Nguyen Thi Anh Nguyen Thi Anh"
    },
    {
        "id": 322723,
        "userId": null,
        "name": "Nguyen Thi Thanh Huyen",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "huyenntt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "KfefSfb8dwFonQnJ54vUzoA37OgylWr69P1JHlw6ipdl0kIVqx",
            "name": "Nguyen Thi Thanh Huyen",
            "uniqueId": "huyenntt-WuC"
        },
        "keyword": "Nguyen Thi Thanh Huyen Nguyen Thi Thanh Huyen"
    },
    {
        "id": 318547,
        "userId": null,
        "name": "Nguyen Thi Thu Trang",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "trangntt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "qDYphNd9niHD9No4L2Wzyb0r0J7vn89asiwXbhStjfWZVFQpZ8",
            "name": "Nguyen Thi Thu Trang",
            "uniqueId": "trangntt-ekt"
        },
        "keyword": "Nguyen Thi Thu Trang Nguyen Thi Thu Trang"
    },
    {
        "id": 318518,
        "userId": null,
        "name": "Nguyen Trang",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "trangnt2@relipasoft.com",
        "nulabAccount": {
            "nulabId": "dRho7OA4zC3JXXj3hui7Axitp6NNOIqv7liERl643xoeMyvwir",
            "name": "Nguyen Trang",
            "uniqueId": "trangnt2-QKN"
        },
        "keyword": "Nguyen Trang Nguyen Trang"
    },
    {
        "id": 321829,
        "userId": null,
        "name": "Nguyen Van Khang",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "khangnv@relipasoft.com",
        "nulabAccount": {
            "nulabId": "OmWOCakeZCzXcM9ySMCov8QEdTcyy41f4Y9CmAzK8xtLbW75l7",
            "name": "Nguyen Van Khang",
            "uniqueId": "khangnv-atp"
        },
        "keyword": "Nguyen Van Khang Nguyen Van Khang"
    },
    {
        "id": 318556,
        "userId": null,
        "name": "Nguyễn Văn Trường",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "truongnv@relipasoft.com",
        "nulabAccount": {
            "nulabId": "dhh6AOp2XroaiX9ubA9bTrsiXwTg1F4B8WyPPUTkcEGt8mFF5k",
            "name": "Nguyễn Văn Trường",
            "uniqueId": "truongnv-z7J"
        },
        "keyword": "Nguyễn Văn Trường Nguyễn Văn Trường"
    },
    {
        "id": 318570,
        "userId": null,
        "name": "Nguyễn Viết Hiếu",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "hieunv@relipasoft.com",
        "nulabAccount": {
            "nulabId": "9r0wWgDR9VRiIbR8i2uhm1Fnq50SugNiih744nsdOVgZEzoxlC",
            "name": "Nguyễn Viết Hiếu",
            "uniqueId": "hieunv-LVY"
        },
        "keyword": "Nguyễn Viết Hiếu Nguyễn Viết Hiếu"
    },
    {
        "id": 318561,
        "userId": null,
        "name": "nhinhdt",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "nhinhdt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "bKMlLiDxf74o7KIRJDLWPd6FaHg02IF41Z5gwSosQBfiOyTZ4v",
            "name": "NhinhDT",
            "uniqueId": "nhinhdt-tAT"
        },
        "keyword": "nhinhdt nhinhdt"
    },
    {
        "id": 318548,
        "userId": null,
        "name": "NhungDTH",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "nhungdth@relipasoft.com",
        "nulabAccount": {
            "nulabId": "3S9llCjZQZw1IcVqRKr4RjXiHkid6mWt9qo4Jdd9d0ajTK3Cvh",
            "name": "NhungDTH",
            "uniqueId": "nhungdth"
        },
        "keyword": "NhungDTH NhungDTH"
    },
    {
        "id": 318524,
        "userId": null,
        "name": "nhungnth",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "nhungnth@relipasoft.com",
        "nulabAccount": {
            "nulabId": "fFBxpcAUGMnN13G1fOTPFWyeONEOwBorEU0zJcDAcioefviaWP",
            "name": "nhungnth",
            "uniqueId": "nhungnth-8GO"
        },
        "keyword": "nhungnth nhungnth"
    },
    {
        "id": 318546,
        "userId": null,
        "name": "QuanNA",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "quanna@relipasoft.com",
        "nulabAccount": {
            "nulabId": "uZD74I5Ce7kJkOP1ZyZwGqsC3EjC8LKPoBSq3WCXusQZHfoqyD",
            "name": "QuanNA",
            "uniqueId": "quanna-Yfq"
        },
        "keyword": "QuanNA QuanNA"
    },
    {
        "id": 322074,
        "userId": null,
        "name": "QuynhTT",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "quynhtt7335@relipasoft.com",
        "nulabAccount": {
            "nulabId": "Ctz0Fgr0rYxq2Is0HlZFm7YxbrSQChizUeeDsTPrLWooNHxiUC",
            "name": "QuynhTT",
            "uniqueId": "quynhtt7335"
        },
        "keyword": "QuynhTT QuynhTT"
    },
    {
        "id": 322694,
        "userId": null,
        "name": "sonth",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "sonth@relipasoft.com",
        "nulabAccount": {
            "nulabId": "YW30mFz7utoTSpJnO7Naj4who0AM59PkRdiyBT0YBG8Ik7L5g3",
            "name": "sonth",
            "uniqueId": "sonth-BWP"
        },
        "keyword": "sonth sonth"
    },
    {
        "id": 318579,
        "userId": null,
        "name": "hauttv",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "hauttv@relipasoft.com",
        "nulabAccount": {
            "nulabId": "pXjiP1OsGqEcU87lGPeBTu2VhBa76kGNwsnjXzEVdBoI3aa2kT",
            "name": "Trần Thị Viết Hậu",
            "uniqueId": "hauttv"
        },
        "keyword": "Trần Thị Viết Hậu Trần Thị Viết Hậu"
    },
    {
        "id": 318558,
        "userId": null,
        "name": "dungtt",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "dungtt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "XE0Xxn8OeLTCAsH3sAfTdGTEcEsaLUMunDhsfgbBYkJKzWoxZ6",
            "name": "Tran Tuan Dung",
            "uniqueId": "dungtt-Mgv"
        },
        "keyword": "Tran Tuan Dung Tran Tuan Dung"
    },
    {
        "id": 318528,
        "userId": null,
        "name": "anhvt",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "anhtv@relipasoft.com",
        "nulabAccount": {
            "nulabId": "an4Vzm9O3AdPXjHp1UBAQhuJ7mjxDBMTrFEkc6RL6ZMqgBUgOd",
            "name": "Trần Vân Anh",
            "uniqueId": "anhtv-krp"
        },
        "keyword": "Trần Vân Anh Trần Vân Anh"
    },
    {
        "id": 318512,
        "userId": null,
        "name": "ductt",
        "roleType": 1,
        "lang": "en",
        "mailAddress": "duc@relipasoft.com",
        "nulabAccount": {
            "nulabId": "YvMRIbP9V4nv0vGxdvxAg5Ydus0MpSdH6BaGvjFNa1iQ80M5S6",
            "name": "Tran Xuan Duc",
            "uniqueId": "ductx"
        },
        "keyword": "Tran Xuan Duc Tran Xuan Duc"
    },
    {
        "id": 318551,
        "userId": null,
        "name": "trangnt",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "trangnt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "2z0UDHqT2EdtmwsMwCylJkjiSKZpx91SDWoR31xyiaKhFGzFEV",
            "name": "trangnt",
            "uniqueId": "trangnt-VwP"
        },
        "keyword": "trangnt trangnt"
    },
    {
        "id": 322724,
        "userId": null,
        "name": "TrangNTH",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "trangnth@relipasoft.com",
        "nulabAccount": {
            "nulabId": "5JjPMU4hB443ZDl3H4qrqyx1KBsHzFIHfV2AtCDTkzbnXwQRgj",
            "name": "TrangNTH",
            "uniqueId": "trangnth-UlP"
        },
        "keyword": "TrangNTH TrangNTH"
    },
    {
        "id": 318572,
        "userId": null,
        "name": "cuongtm",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "cuongtm@relipasoft.com",
        "nulabAccount": {
            "nulabId": "xgi0HtzItdbq57f8XkieLZWz4dZOWEVTsHJYyjQHFBmnWeB2HO",
            "name": "Trịnh Mạnh Cường",
            "uniqueId": "cuongtm"
        },
        "keyword": "Trịnh Mạnh Cường Trịnh Mạnh Cường"
    },
    {
        "id": 322055,
        "userId": null,
        "name": "TuanPX",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "tuanpx7367@relipasoft.com",
        "nulabAccount": {
            "nulabId": "V7q3dgDOobagaM9xG7JJ3S67xy9JL6cpW5qwBFeAP9kpJUMBuO",
            "name": "TuanPX",
            "uniqueId": "tuanpx7367"
        },
        "keyword": "TuanPX TuanPX"
    },
    {
        "id": 318522,
        "userId": "*j9XGjHUKhA",
        "name": "TungDT",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "tungdt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "MFIzrGn8gLp36dua8hZ3vwUGcZbU76Qsb7ycC4RFewqJFPeQv1",
            "name": "TungDT",
            "uniqueId": "tungdt"
        },
        "keyword": "TungDT TungDT"
    },
    {
        "id": 318578,
        "userId": null,
        "name": "viettt",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "viettt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "hC6NHj8ymdeMG8mQdWlPG3R0lYjxJjtxElUPSHasb6HmVXHsgg",
            "name": "Viet Tran",
            "uniqueId": "viettt"
        },
        "keyword": "Viet Tran Viet Tran"
    },
    {
        "id": 318515,
        "userId": null,
        "name": "vinhvv",
        "roleType": 1,
        "lang": "en",
        "mailAddress": "vinhvv@relipasoft.com",
        "nulabAccount": {
            "nulabId": "7lJmTNK1dUbzEAMbqslRRYnaX28wHR3wOdwxyYZXleY3RMZpWR",
            "name": "Vu Van Vinh",
            "uniqueId": "vinhvv"
        },
        "keyword": "Vu Van Vinh Vu Van Vinh"
    },
    {
        "id": 321140,
        "userId": null,
        "name": "xuanntt",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "xuanntt@relipasoft.com",
        "nulabAccount": {
            "nulabId": "SA11TIa8O10U4wj2GXMFxh2KPTiOqLOuLYhX8rl6ioRG2OCymT",
            "name": "Nguyen Thi Thanh Xuan",
            "uniqueId": "xuanntt"
        },
        "keyword": "xuanntt xuanntt"
    },
    {
        "id": 318552,
        "userId": null,
        "name": "yenvtb",
        "roleType": 2,
        "lang": "en",
        "mailAddress": "yenvtb@relipasoft.com",
        "nulabAccount": {
            "nulabId": "HKNPBzO3f2t6jVNPLAlNtXP3X9crkpTq2GblyOLktgY53pay1d",
            "name": "yenvtb",
            "uniqueId": "yenvtb-att"
        },
        "keyword": "yenvtb yenvtb"
    },
    {
        "id": 319397,
        "userId": null,
        "name": "トゥエン",
        "roleType": 1,
        "lang": "ja",
        "mailAddress": "tuyen@relipasoft.com",
        "nulabAccount": {
            "nulabId": "YSs6SnkoNrXt7ysAx3ZYhtdu1HzoGKlZiUWJwX96SWAcZ7dYtB",
            "name": "トゥエン",
            "uniqueId": "tuyen-relipa"
        },
        "keyword": "トゥエン TOゥEN"
    },
    {
        "id": 319609,
        "userId": null,
        "name": "田中　潤",
        "roleType": 2,
        "lang": "ja",
        "mailAddress": "tanaka.j@relipasoft.com",
        "nulabAccount": {
            "nulabId": "cYgFo1fUnUBLL5Lo2kEU1sBGoFQY4rZXFrGQD5mEqjhzNgF44J",
            "name": "田中　潤",
            "uniqueId": "tanakaj-FVI"
        },
        "keyword": "田中　潤 TANAKA ZYUN"
    }
]
