import axios from "axios";
import { promotionClient, getConfig } from "./const";


export function getIssueTypeAPI(cb) {
  return promotionClient.get("http://relipa.backlog.com/api/v2/projects/67962/issueTypes");
}
export function getCategoryAPI() {
  return promotionClient.get(`http://relipa.backlog.com/api/v2/projects/67962/categories`);
}
export function getMilestoneAPI() {
  return promotionClient.get(`http://relipa.backlog.com/api/v2/projects/67962/versions`);
}
export function getPriorityAPI() {
  return promotionClient.get(`http://relipa.backlog.com/api/v2/priorities`);
}
export function getStatusAPI() {
  return promotionClient.get(`http://relipa.backlog.com/api/v2/projects/67962/statuses`);
}
export function getAssigneeAPI() {
  return promotionClient.get(`http://relipa.backlog.com/api/v2/projects/67962/users`);
}
export function getListIssueAPI() {
  return promotionClient.get(`http://relipa.backlog.com/api/v2/issues`);
}
export function getCountIssueAPI() {
  return axios(getConfig('get', `api/v2/issues/count`));
}
