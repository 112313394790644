import React, { useState, useEffect } from "react";
import * as xlsx from 'xlsx';
import moment from "moment";
import { Button, notification, Alert, Steps, message, Table } from "antd";
import { FileUploader } from "react-drag-drop-files";
import "./NewReport.css";
import {
  ExcelDateToJSDateData,
  fomatDataKey,
} from "../../utils/api_call/const";
import { promotionClient, listUser } from "../../utils/api_call/const";
import * as FileSaver from "file-saver";
import { DownloadOutlined } from "@ant-design/icons";

const NewReport = (props) => {
  const [current, setCurrent] = React.useState(0);
  const [dataTemplace, setDataTemplace] = useState([]);
  const [isDisableBtn, setIsDisableBtn] = useState(false);

  useEffect(() => {
    if(props.count) {
      setDataTemplace([])
      setCurrent(0)
    }
  }, [props.count]);
  let obj = {
    id: null,
    summary: "",
    projectId: props.projectId,
    parentIssueId: null,
    description: "",
    startDate: "",
    dueDate: "",
    estimatedHours: null,
    actualHours: null,
    issueTypeId: null,
    categoryId: null,
    versionId: null,
    milestoneId: null,
    priorityId: 1,
    assigneeId: null,
    notifiedUserId: null,
    attachmentId: null,
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const { Step } = Steps;

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        convertExcelDataToBacklogData(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const convertExcelDataToBacklogData = (arr) => {
    let newData = arr.map((item, i) => {
      return {
        ...obj,
        summary: item['Subject'],
        id: item['ID'],
        // estimatedHours: item['Estimated Hours'] || null,
        actualHours: item['Actual Hours'] || null,
        keyId: item['Key'] || null,
        // startDate: ,
        startDate: moment(item['Start Date']).format('YYYY-MM-DD'), // ExcelDateToJSDateData(item["Start Date"])
        dueDate: moment(item['Due date']).format('YYYY-MM-DD'),
        comment: item["Comment1"] || "",
        assignee: item["Assignee"] || null,
        customField_8003: item['Done ratio (%)'] || 0,
        issueTypeId: item['Issue Type ID'] || 298203,
        parent: item['Parent'],
        categoryName: item['Category Name'],
        issueType: item['Issue Type'],
        updated: item['Updated'],
        done: item['Done ratio (%)'],
        status: item['Status']
      };
    });

    setDataTemplace(newData);
  };

  const columns = [
    {
      title: "KeyId",
      dataIndex: "keyId",
      key: "keyId",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Issue name",
      dataIndex: "summary",
      key: "summary",
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
    },

    // {
    //   title: "estimate",
    //   dataIndex: "estimatedHours",
    //   key: "estimatedHours",
    // },
    // {
    //   title: "actualHours",
    //   dataIndex: "actualHours",
    //   key: "actualHours",
    // },
    {
      title: "Start",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End",
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: "Done ratio (%)",
      dataIndex: "customField_8003",
      key: "customField_8003",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Parent",
      dataIndex: "parent",
      key: "parent",
    },
  ];

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = `Report ${new Date().toLocaleDateString()}`;

  const ExportToCSV = (dataSource, fileName) => {
    console.log(dataSource)
    let dataExportToExcel = dataSource.map((item) => {
      return {
        keyId: item.keyId,
        summary: item.summary,
        assignee: item.assignee,
        startDate: moment(item.startDate).format('YYYY-MM-DD'),
        dueDate: moment(item.dueDate).format('YYYY-MM-DD'),
        // estimatedHours: item.estimatedHours,
        status: item.status,
        issueType: item.issueType,
        done: item.done,
        categoryName: item.categoryName,
        updated: moment(item.updated).format('YYYY-MM-DD, h:mm:ss')
      };
    });

    let countOpenStatus = 0;
    let countInProgessStatus = 0;
    let countResolvedStatus = 0;
    let countClosedStatus = 0;
    let countPendingStatus = 0;
    let countReopenStatus = 0;

    const arrMilestone = [];
    const arrTimeUpdate = [];

    const listMilestone = Array.from(new Set(arrMilestone));
    const listTimeUpdate = Array.from(new Set(arrTimeUpdate));

    const analysisEstimation = listMilestone.map((milestoneName) => {
      const a = dataSource.filter(
        (item) =>
          (item.milestone[0] ? item.milestone[0].name : "") === milestoneName
      );

      const totalEstimateHours = a.reduce((total, value) => {
        return total + (value.estimatedHours ? value.estimatedHours : 0);
      }, 0);

      const totalActualHours = a.reduce((total, value) => {
        return total + (value.actualHours ? value.actualHours : 0);
      }, 0);

      return {
        Milestone: milestoneName,
        "Total Estimate Hours": totalEstimateHours,
        "Total Actual Hours": totalActualHours,
      };
    });

    const analysisToExcel = () => {
      return [
        {
          Status: "Count",
          Open: countOpenStatus,
          Inprogess: countInProgessStatus,
          Resolved: countResolvedStatus,
          Closed: countClosedStatus,
          Pending: countPendingStatus,
          Reopen: countReopenStatus,
          "": "",
          " ": " ",
        },
        ...analysisEstimation,
      ];
    };

    const dataExportJson = xlsx.utils.json_to_sheet(dataExportToExcel);
    const statisticJson = xlsx.utils.json_to_sheet(analysisToExcel());

    const wb = { SheetNames: [], Sheets: {} };
    wb.SheetNames.push("Report");
    wb.Sheets["Report"] = dataExportJson;

    if (listTimeUpdate.length > 1) {
      wb.SheetNames.push("Analysis");
      wb.Sheets["Analysis"] = statisticJson;
    }

    const data = new Blob(
      [xlsx.write(wb, { bookType: "xlsx", type: "array" })],
      {
        type: fileType,
      }
    );

    FileSaver.saveAs(data, fileName + fileExtension);

  };


  const img = '../../public/images/issue.PNG'
  const First = () => {
    return <div className="step-main">
      <div className="step-head">
        <p>Tính năng này hỗ trợ trích xuất danh sách issue từ backlog tạo báo cáo ( Báo cáo ngày/tuần/tháng - lọc theo điều kiện tìm kiếm của bạn  )</p>
        <p>Hãy đảm bảo rằng bạn đã tải về file excel của backlog, nếu đã có vui lòng bỏ qua chọn <b>Next</b> nếu chưa có hãy làm theo hướng dẫn ở dưới</p>
        <p>Vui lòng đăng nhập tài khoản <a target='_blank' href="https://relipa.backlog.com/">backlog của bạn</a>, sau đó  </p>
        <p>chọn <b>Project</b></p>
        <p>chọn <b>Issues</b></p>
        <p>chọn <b>Search conditions</b> để tạo điều kiện search</p>
        <p>sau đó dropdown menu ở góc tay phải, chọn <b>Excel</b> để tải về</p>
      </div>
    </div>;
  };
  const Second = () => {
    return <div className="step-main">
        <div>
          <p className="text-center">Vui lòng tải lên file của bạn</p>
        </div>
        <div className="step-upload">
        <div className="d-flex">
          <form style={{ padding: "0 10px" }}>
            <label className='btn-upload' htmlFor="ReportUpload">Upload File</label>
            <input
              style={{ display: 'none' }}
              type="file"
              name="upload"
              id="ReportUpload"
              onChange={readUploadFile}
            />
          </form>
        </div>
          { dataTemplace.length > 0 && (
            <Alert style={{width: '30%'}} message="Tải lên thành công" type="success" showIcon />
          ) }
          {
            dataTemplace.length > 0 && (
            <div className="mt-20">
              <Table
                bordered
                columns={columns}
                dataSource={fomatDataKey(dataTemplace)}
              />
            </div>

            )
          }

        
        </div>
    </div>;
  };
  const Last = () => {
    return <div style={{textAlign: "center"}} className="step-main">
      <Button onClick={() => ExportToCSV(dataTemplace, fileName) } type="primary" style={{marginRight: '10px'}} icon={<DownloadOutlined />} className="mb-20 mt-20">Dowload Report</Button>
    </div>;
  };

  const steps = [
    {
      title: "Chuẩn bị",
      content: <First />,
    },
    {
      title: "Upload",
      content: <Second />,
    },
    {
      title: "Dowload",
      content: <Last />,
    },
  ];

  return (
    <>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {current < steps.length - 1 &&  (
          <Button disabled={current === 1 && dataTemplace.length === 0} type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {/* {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )} */}
      </div>
    </>
  );
};

export default NewReport;
