import React, { useState, useEffect } from 'react'
import * as xlsx from 'xlsx';
import moment from 'moment';
import axios from "axios";
import * as FileSaver from "file-saver";
import { Button, notification, Card, Tooltip, Alert, Steps, Radio, Modal, Table } from "antd";
import UploadTemplace from './UploadTemplace';
import { DownloadOutlined } from '@ant-design/icons';
import { ExcelDateToJSDateData, fomatDataKey } from "../utils/api_call/const";
import { ISSUE_TYPE_COLOR } from '../utils/constantVar';
import './UploadXlsx.css'

const UploadXlsx = (props) => {
  const [dataBacklog, setDataBacklog] = useState([]);
  const [dataTemplace, setDataTemplace] = useState([]);
  const [countIssue, setCountIssue] = useState(0);
  const [errorList, setErrorList] = useState([]);
  const [listIssueAlert, setListIssueAlert] = useState([]);
  const [current, setCurrent] = React.useState(0);
  const [changeType, setChangeType] = React.useState(true);
  useEffect(() => {
    if (props.count) {
      setDataBacklog([])
      setDataTemplace([])
      setCurrent(0)
    }
  }, [props.count]);
  let obj = {
    id: null,
    summary: "",
    projectId: props.projectId,
    parentIssueId: null,
    description: "",
    startDate: "",
    dueDate: "",
    estimatedHours: null,
    actualHours: null,
    issueTypeId: null,
    categoryId: null,
    versionId: null,
    milestoneId: null,
    priorityId: 3,
    assigneeId: null,
    notifiedUserId: null,
    attachmentId: null,
  };
  const config = {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    }
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Issue name",
      dataIndex: "summary",
      key: "summary",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
    },

    {
      title: "estimate",
      dataIndex: "estimatedHours",
      key: "estimatedHours",
    },
    {
      title: "Start",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End",
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "mileStone",
      dataIndex: "milestoneId",
      key: "milestone",
    },
    {
      title: "category",
      dataIndex: "categoryId",
      key: "category",
    },
    {
      title: "issue type",
      dataIndex: "issueTypeId",
      key: "issueType",
    },
    {
      title: "Parent",
      dataIndex: "parent",
      key: "parent",
    },
  ];
  const convertExcelDataToBacklogData = (arr) => {
    let newData = arr.map((item, i) => {
      return {
        ...obj,
        summary: item['Subject'],
        id: item['ID'],
        estimatedHours: item['Estimated Hours'] || null,
        actualHours: item['Actual Hours'] || null,
        startDate: moment(item['Start Date']).format('YYYY-MM-DD'), // ExcelDateToJSDateData(item["Start Date"])
        dueDate: moment(item['Due date']).format('YYYY-MM-DD'),
        comment: item["Comment1"] || moment(Date.now()).format('YYYY/MM/DD, h:mm:ss'),
        assigneeId: item["Assignee ID"], // handleCheckAssignee(item["Resource"]),
        assignee: item["Assignee"] || null,
        customField_8003: item['Done ratio (%)'] || 0,
        description: item.description || null,
        issueTypeId: item.issueType || null,
        categoryId: item.category || null,
        milestoneId: item.milestone || null,
        parent: item['Parent']
      };
    });
    setDataTemplace(newData);
    notification.success({
      message: `Upload success`,
      description: `Upload success`,
      className: 'custom-class',
      style: {
        width: 400,
      },
    });
  };

  const convertExcelTemplaceDataToBacklogData = (arr) => {
    let newData = arr.map((item, i) => {
      return {
        ...obj,
        summary: item.summary,
        id: item.id || "_______",
        estimatedHours: item['estimatedHours'] || null,
        actualHours: item.actualHours || null,
        // startDate: ,
        startDate: ExcelDateToJSDateData(item["startDate"]), // ExcelDateToJSDateData(item["Start Date"])
        dueDate: ExcelDateToJSDateData(item["dueDate"]),
        comment: item["comment"] || moment(Date.now()).format('YYYY/MM/DD, h:mm:ss'),
        assigneeId: item.assigneeId || handleCheckAssignee(item["assignee"]),
        assignee: item["assignee"] || null,
        issueTypeId: item.issueType || null,
        description: item.description || null,
        categoryId: item.category || null,
        milestoneId: item.milestone || null,
        parent: item['parent']
      };
    });
    setDataBacklog(newData);
  };
  const handleCheckAssignee = (name) => {
    let assigneeID = null;
    let newListUser = props.memberOfProject.map(item => {
      let i = item.mailAddress.indexOf("@")
      let name = item.mailAddress.slice(0, i);
      item.userName = name
      return item
    })
    newListUser.map(item => {
      if (item.userName?.toUpperCase() === name?.toUpperCase()) {
        assigneeID = item.id
      }
    })
    return assigneeID
  }
  const callApi = () => {
    ExportToCSV(dataTemplace, fileName)
  }
  let mockArr = []

  let getID = {}
  const handleCreateAllIssue = async () => {
    const create = []
    const update = []

    await checkAndCreateIssueTypeList()
    await checkAndCreateCategory()
    await checkAndCreateMileStone()
    
    // return

    dataBacklog.forEach((issue) => {
      if(issue.assignee){
        props.memberOfProject.includes()
      }
      if (issue.id && issue.id !== "_______" && issue?.parent?.length && issue?.parent[0] === "*") {
        update.push(issue)
      } else if (issue.id && issue.id !== "_______" && issue.id[0] !== "#" && issue.id[0] !== "*") {
        update.push(issue)
      } else if (issue.id[0] === "*") {
        create.push(issue)
      } else if (issue.id && issue.id === "_______") {
        // createIssue(issue, "parent")
        create.push(issue)
      } else if (issue.id && issue.id[0] === "#") {
        create.push(issue)
      }

    });
    if (create.length > 0) {
      try {
        for (let i = 0; i < create.length; i++) {
          let parentID = create[i].id === "_______" ? null : create[i].id[0]
          if (create[i].parent === create[i].id) {
            alert('Trùng id và parentId')
          } else {
            if (parentID === "#" || parentID === "*") {
              await createIssue(create[i], 'parent')
            }
            if (create[i].parent && create[i]?.parent[0] === "#") {
              await createIssue(create[i], 'child')
            }
          }
          if (i === create.length - 1) {
            if (update.length > 0) {
              try {
                for (let i = 0; i < update.length; i++) {
                  await updateIssue(update[i])
                }
              } catch (error) {
                console.log(error)
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  };

  const checkAndCreateIssueTypeList = async () => {
    const dataCreateIssueType = []
    const undefineIssueType = Array.from(new Set(dataBacklog.map(item => item.issueTypeId))).map(issueType => issueType?.toLowerCase())
      .filter(item => !props.IssueTypeList.find(issueTypeListItem => issueTypeListItem?.name.toLowerCase() === item)).map(item => item?.trim()).filter(item => !!item)

    if (undefineIssueType.length) {
      const result = undefineIssueType.map(async (item) => await createIssueType(item))
      dataCreateIssueType.push(await Promise.all(result).flat())
    }

    const listIssueTypeAfterCreate = props.IssueTypeList.concat(dataCreateIssueType).filter(item => !!item)

    setDataBacklog(prevState => prevState.map(item => {
      const issueTypeIdData = listIssueTypeAfterCreate.find(issueTypeItem => issueTypeItem.name?.toLowerCase() === item.issueTypeId?.toString()?.toLowerCase())?.id

      if(issueTypeIdData) {
        item.issueTypeId = issueTypeIdData || item.issueTypeId
      }
      return item
    }))
  }

  const checkAndCreateCategory = async () => {
    const dataCreateCategory = []

    const undefineCategory = Array.from(new Set(dataBacklog.map(item => item.categoryId?.toLowerCase().split(',')).flat()
      .map(item => item?.trim()).filter(item => !props.categories
        .find(categoryItem => categoryItem.name?.toLowerCase().trim() === item?.trim())))).flat()

    if (undefineCategory.length) {
      const result = undefineCategory.map(async(item) => await createCategory(item))
      dataCreateCategory.push(await Promise.all(result))
    }
    
    const listCategoryAfterCreate = props.categories.concat(dataCreateCategory.flat()).filter(item => !!item)

    setDataBacklog(prevState => prevState.map(item => {
      item.categoryId = item.categoryId?.toLowerCase().trim().split(',')
        .map(categoryItem => listCategoryAfterCreate
          .find(categoryAfterCreateItem => categoryAfterCreateItem.name.toLowerCase().trim() === categoryItem.trim()).id) || null
      return item
    }))
  }

  const checkAndCreateMileStone = async () => {
    const dataCreateMileStone = []

    const undefineMileStone = Array.from(new Set(dataBacklog.map(item => item.milestoneId?.toLowerCase().split(',')).flat()
      .map(item => item?.trim()).filter(item => !props.milestones
        .find(milestoneItem => milestoneItem.name?.toLowerCase().trim() === item?.trim())))).flat()
      
    if (undefineMileStone.length) {
      const result = undefineMileStone.map(async (item) => await createMileStone(item))
      dataCreateMileStone.push(await Promise.all(result))
    }

    const listMileStoneAfterCreate = props.milestones.concat(dataCreateMileStone.flat()).filter(item => !!item)

    setDataBacklog(prevState => prevState.map(item => {
      item.milestoneId = item.milestoneId?.toLowerCase().trim().split(',')
        .map(milestoneItem => listMileStoneAfterCreate
          .find(milestoneAfterCreateItem => milestoneAfterCreateItem?.name.toLowerCase().trim() === milestoneItem?.trim()).id) || null
      return item
    }))
  }

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        convertExcelDataToBacklogData(json);

      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const checkValidate = (errors) => {
    let patternDate = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/

    let newArr = []
    errors.forEach((item, index) => {
      if (!item.summary) {
        newArr.push({ message: `Issue name dòng số ${index + 1} - Issue name không được để trống` })
      }
      if (isNaN(item.estimatedHours)) {
        newArr.push({ message: `Estimate: dòng số ${index + 1} - Ký tự {${item.estimatedHours}} yêu cầu phải là chữ số ` })
      }
      let assignee = handleMemberOfProject(item.assignee)
      if (assignee) {
        newArr.push({ message: `Assignee: dòng số ${index + 1} - {${assignee}}  sai tên hoặc member không có trong dự án` })
      }
      // if (item.startDate) {
      //   let result = patternDate.test(item.startDate);
      //   if (!result) {
      //     newArr.push({ message: `StartDate: dòng số ${index + 1} - {${item.startDate}} vui lòng nhập đúng định dạng YYYY-MM-DD` })
      //   }
      // }
      // if (item.dueDate) {
      //   let result = patternDate.test(item.dueDate);
      //   if (!result) {
      //     newArr.push({ message: `DueDate: dòng số ${index + 1} - {${item.dueDate}} vui lòng nhập đúng định dạng YYYY-MM-DD` })
      //   }
      // }
      setErrorList(newArr)
    })
  }

  const handleMemberOfProject = (assignee) => {
    let newListUser = props.memberOfProject.map(item => {
      let i = item.mailAddress.indexOf("@")
      let name = item.mailAddress.slice(0, i);
      return name.toUpperCase()
    })
    let result = newListUser.includes(assignee?.toUpperCase())
    if (!result) {
      return assignee
    }
  }
  const RenderAlert = () => {
    return errorList.map((item, index) => (<Alert key={index} style={{ marginTop: '10px' }} message={item.message} type="error" showIcon />))
  }
  const readUploadFileImportTemplace = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        checkValidate(json)
        convertExcelTemplaceDataToBacklogData(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }

  };
  let saveParent = []
  const reupdateData = (obj, id) => {
    const data = Object.assign({}, obj)
    data.comment = obj.comment || moment(Date.now()).format('YYYY/MM/DD, h:mm:ss a')
    delete data.key
    delete data.id
    delete data.assignee
    delete data.projectId
    delete data.parent
    axios.patch(`https://relipa.backlog.com/api/v2/issues/${id}`, data, config)
      .then(function (res) {
        notification.warning({
          message: `Notification Update`,
          description: `Update success ${res.data.summary}`,
          className: 'custom-class',
          style: {
            width: 400,
          },
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  const updateIssue = (issue) => {
    let resultData = dataBacklog.map(item => {
      if (item.parent === "*") {
        let newArr = [...dataBacklog]
        let indexCurrent = newArr.findIndex(x => x.summary === item.summary);

        // let a = arr.find(item => item.Parent !== "*" )
        let parent = indexCurrent - 1
        for (let i = parent; i >= 0; i--) {
          if (newArr[i].parent[0] !== "#" && newArr[i].id !== "_______") {
            newArr = [newArr[indexCurrent].parentIssueId = newArr[parent].id, ...newArr]
          }
        }
      }
      return item
    })
    let data = Object.assign({}, issue)
    const _id = data.id
    delete data.key
    delete data.id
    delete data.assignee
    delete data.projectId


    if (data.parent && data.parent[0] === "*") {
      saveParent.map(item => {
        if (data.parent === item.fakeId) {
          data.parentIssueId = item.id
          return item;
        }
      })
    }
    delete data.parent


    axios.patch(`https://relipa.backlog.com/api/v2/issues/${_id}`, data, config)
      .then(function (response) {
        // arr = [response.data , ...arr]
        // setListIssueAlert(arr)
        notification.warning({
          message: `Notification Update`,
          description: `Update success ${response.data.summary}`,
          className: 'custom-class',
          style: {
            width: 400,
          },
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  let count = 0
  let arr = []
  let listError = []

  const createIssue = async (obj, parent) => {

    const data = Object.assign({}, obj)
    data.projectId = props.projectId;
    delete data.id
    delete data.key
    delete data.comment
    delete data.assignee
    if (obj.parent && obj.parent[0] === "#") {
      mockArr.map(item => {

        if (data.parent === item.fakeId) {
          data.parentIssueId = item.id
          return item;
        }

      })
    }



    delete data.parent
    try {
      const response = await axios.post(`https://relipa.backlog.com/api/v2/issues`, data, config)
      if (response.data) {
        count += 1
        setCountIssue(count)
        arr = [response.data, ...arr]
        setListIssueAlert(arr)
        notification.success({
          message: `Tạo mới issue thành công`,
          description: `Tạo mới issue ${response.data.summary}`,
          className: 'custom-class',
          style: {
            width: 400,
          },
        });
      }
      obj.id[0] === "#" && mockArr.push({ fakeId: obj.id, id: response.data.id })
      obj.id[0] === "*" && saveParent.push({ fakeId: obj.id, id: response.data.id })


      // if (response.data.id && parent === "parent") {
      //   getID.id = response.data.id
      // }
    } catch (error) {
      notification.error({
        message: `Tạo mới thất bại`,
        description: error,
        className: 'custom-class',
        style: {
          width: 400,
        },
      });
      let jsonData = JSON.parse(error.response.config.data)
      error.response.data.errors[0].summary = jsonData.summary
      listError = [error.response.data.errors[0], ...listError]
      setListIssueError(listError)
    }

  };

  const createIssueType = async (issueTypeName) => {
    if(!issueTypeName){
      return
    }

    const params = {
      name: issueTypeName.trim(),
      color: ISSUE_TYPE_COLOR[Math.floor(Math.random() * ISSUE_TYPE_COLOR.length)]
    }

    const { data } = await axios.post(`https://relipa.backlog.com/api/v2/projects/${props.projectId}/issueTypes`, params, config)
    return data
  }

  const createCategory = async (categoryName) => {
    if(!categoryName) {
      return
    }

    const { data } = await axios.post(`https://relipa.backlog.com/api/v2/projects/${props.projectId}/categories`,{ name: categoryName.trim() } , config)
    return data
  }

  const createMileStone = async (mileStoneName) => {
    if(!mileStoneName){
      return
    }

    const params = {
      name: mileStoneName
    }
    const { data } = await axios.post(`https://relipa.backlog.com/api/v2/projects/${props.projectId}/versions`, params, config)
    return data
  }

  const [listIssueError, setListIssueError] = useState([])
  const handleDeleteAllIssue = () => {
    axios.get(`https://relipa.backlog.com/api/v2/issues?count=100`, config)
      .then(function (response) {
        response.data.forEach(item => {
          axios.delete(`https://relipa.backlog.com/api/v2/issues/${item.id}`, config)
            .then(function (response) {
              notification.error({
                message: `Delete success`,
                description: `Update success ${response.data.summary}`,
                className: 'custom-class',
                style: {
                  width: 400,
                },
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = `Issues ${new Date().toLocaleDateString()}`;

  const ExportToCSV = (dataSource, fileName) => {
    let dataExportToExcel = dataSource.map((item) => {
      return {
        id: item.id,
        summary: item.summary,
        assignee: item.assignee,
        // assigneeId: item.assigneeId,
        startDate: moment(item.startDate).format('YYYY-MM-DD'),
        dueDate: moment(item.dueDate).format('YYYY-MM-DD'),
        estimatedHours: item.estimatedHours,
        comment: item.comment,
        parent: item.parent
      };
    });
    let countOpenStatus = 0;
    let countInProgessStatus = 0;
    let countResolvedStatus = 0;
    let countClosedStatus = 0;
    let countPendingStatus = 0;
    let countReopenStatus = 0;
    const arrMilestone = [];
    const arrTimeUpdate = [];
    const listMilestone = Array.from(new Set(arrMilestone));
    const listTimeUpdate = Array.from(new Set(arrTimeUpdate));
    const analysisEstimation = listMilestone.map((milestoneName) => {
      const a = dataSource.filter(
        (item) =>
          (item.milestone[0] ? item.milestone[0].name : "") === milestoneName
      );

      const totalEstimateHours = a.reduce((total, value) => {
        return total + (value.estimatedHours ? value.estimatedHours : 0);
      }, 0);

      const totalActualHours = a.reduce((total, value) => {
        return total + (value.actualHours ? value.actualHours : 0);
      }, 0);

      return {
        Milestone: milestoneName,
        "Total Estimate Hours": totalEstimateHours,
        "Total Actual Hours": totalActualHours,
      };
    });
    const analysisToExcel = () => {
      return [
        {
          Status: "Count",
          Open: countOpenStatus,
          Inprogess: countInProgessStatus,
          Resolved: countResolvedStatus,
          Closed: countClosedStatus,
          Pending: countPendingStatus,
          Reopen: countReopenStatus,
          "": "",
          " ": " ",
        },
        ...analysisEstimation,
      ];
    };
    const dataExportJson = xlsx.utils.json_to_sheet(dataExportToExcel);
    const statisticJson = xlsx.utils.json_to_sheet(analysisToExcel());
    const wb = { SheetNames: [], Sheets: {} };
    wb.SheetNames.push("Report");
    wb.Sheets["Report"] = dataExportJson;

    if (listTimeUpdate.length > 1) {
      wb.SheetNames.push("Analysis");
      wb.Sheets["Analysis"] = statisticJson;
    }

    const data = new Blob(
      [xlsx.write(wb, { bookType: "xlsx", type: "array" })],
      {
        type: fileType,
      }
    );
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const backtopFirst = () => {
    setCurrent(0);
    setChangeType(true)
  };
  const { Step } = Steps;

  const First = () => {
    return <div className="step-main">
      <div className="step-head">
        <h3>
          <Radio.Group onChange={handleChangeType} value={changeType}>
            <Radio value={true}><b>1. Tạo mới issue</b> (Mặc định) - Tạo mới nhanh hàng loạt issue</Radio>
            <br></br>
            <Radio value={false}><b>2. Convert issue</b> - Đồng bộ và chỉnh sửa tất cả issue hiện với <b>Project</b> bạn quản lý</Radio>
          </Radio.Group>
        </h3>
        {!changeType ?
          <div>
            <p>Tính năng này hỗ trợ trích xuất danh sách issue mới nhất từ backlog giúp bạn chỉnh sửa và cập nhật </p>
            <p>Hãy đảm bảo rằng bạn đã tải về file excel của backlog, nếu đã có vui lòng bỏ qua chọn <b>Next</b> nếu chưa có hãy làm theo hướng dẫn ở dưới</p>
            <p>Vui lòng đăng nhập tài khoản <a target='_blank' href="https://relipa.backlog.com/">backlog của bạn</a>, sau đó  </p>
            <p>chọn <b>Project</b></p>
            <p>chọn <b>Issues</b></p>
            <p>chọn <b>Search conditions</b> để tạo điều kiện search</p>
            <p>sau đó dropdown menu ở góc tay phải, chọn <b>Excel</b> để tải về</p>
          </div>
          :
          <div>
            <p>Tính năng giúp người dùng tạo nhiều issue cùng lúc, bạn cần chỉnh sửa trên file excel mẫu, sau đó đồng bộ lên hệ thống của backlog</p>
            <p>Nếu muôn tạo mới issues hãy chọn <b>Next</b></p>
          </div>}
      </div>
    </div>;
  };
  const Second = () => {
    return <div className="step-main">
      {
        changeType && (
          <div style={{ maxWidth: '600px', margin: 'auto' }} >
            <p>Nếu bạn chưa có file tạo mới issue vui lòng <b> <a href="https://relipasoft.sharepoint.com/:x:/s/D1-d1-leader/EetCdNxIQHxDo13_PS5cqIEBw_P7hYfMWly2QM1DSppsJg?e=u3DV6t" target={'_blank'} download>tải về teamplace mẫu</a></b> </p>
            <p>Nếu bạn đã có rồi vui lòng mở file excel để chỉnh sửa</p>
            <p><b>Lưu ý </b>trước khi upload file vui lòng kiểm tra:</p>
            <p><b>1.</b> Trường <span style={{ color: 'red' }}>Assignee bắt buộc</span> và cần viết đúng fomat ( ví dụ: haidn@relipasoft.com vậy assignee đúng là haidn )</p>
            <p><b>2.</b> Hãy đảm bảo rằng bạn đã add đủ các <span style={{ color: 'red' }}>member trong project </span></p>
            <p><b>3.</b> không để dòng trắng không có nội dung</p>
          </div>
        )
      }
      <div className="step-upload">
        <div>
          {
            !changeType && (
              <>
                <p>Vui lòng chọn file excel vào vừa tải về từ <a target='_blank' href="https://relipa.backlog.com/"></a></p>
                <form style={{ padding: "0 10px" }}>
                  <label className='btn-upload' htmlFor="upload">Upload File excel backlog</label>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    name="upload"
                    id="upload"
                    onChange={readUploadFile}
                  />
                </form>
              </>
            )
          }
          {
            changeType && (
              <Tooltip title="prompt text" color={"blue"} key={"1"}>
                <UploadTemplace readUploadFileImportTemplace={readUploadFileImportTemplace} />
              </Tooltip>
            )
          }
        </div>
        {dataTemplace.length > 0 && !changeType && (
          <Alert style={{ width: '30%', marginBottom: '20px' }} message="Tải lên thành công" type="success" showIcon />)
        }
        {dataBacklog.length > 0 && changeType && (
          <Alert style={{ width: '30%', marginBottom: '20px' }} message="Tải lên thành công" type="success" showIcon />)
        }
        {
          dataTemplace.length > 0 && !changeType && (
            <div className="mt-20">
              <Table
                bordered
                columns={columns}
                dataSource={fomatDataKey(dataTemplace)}
              />
            </div>)
        }
        {
          dataBacklog.length > 0 && changeType && (
            <div >
              <Table
                bordered
                columns={columns}
                dataSource={fomatDataKey(dataBacklog)}
              />
            </div>)
        }
        {
          errorList.length > 0 && <RenderAlert />
        }
      </div>
    </div>;
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const listIssueColumn = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: `Danh sách issue tạo thành công ( ${countIssue} )`,
      dataIndex: 'summary',
      key: 'summary',
      render: text => <a>{text}</a>,
    },
  ]
  const listIssueErrorColumn = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: `Issue tạo lỗi ( ${listIssueError.length} )`,
      dataIndex: 'summary',
      key: 'summary',
    },
    {
      title: `Thông báo lỗi`,
      dataIndex: 'message',
      key: 'message',
      render: text => {
        if (text === 'err.issue.assignee.invalid') {
          return <Tooltip placement="topLeft" title={'Trường assignee nhập chưa đúng hoặc người này chưa có trong Project hiện tại'}>
            <span style={{ color: 'red' }}>{text}</span>
          </Tooltip>
        } else {
          return <span style={{ color: 'red' }}>{text}</span>
        }

      },
    },
  ]
  const Last = () => {
    return <div style={{ textAlign: 'center' }} className="step-main">

      {!changeType && dataTemplace.length > 0 && (
        <Button onClick={callApi} type="primary" disabled={!dataTemplace.length} icon={<DownloadOutlined />} className="ml-20 mb-20 mt-20">Dowload Issue</Button>)
      }

      {
        changeType && (
          <Tooltip title="Tiến hành tạo mới và câp nhật hàng loạt issue" color={"blue"} key={"2"}>
            <Button onClick={handleCreateAllIssue} disabled={!dataBacklog.length} type="primary" className="mb-20 mt-20">Tạo mới Issues</Button>
          </Tooltip>
        )
      }
      {
        listIssueAlert.length > 0 && (
          <div className='count-alert-success d-flex'>
            <Card style={{ width: 300 }}>
              <div style={{ cursor: 'pointer' }}>
                <Alert onClick={showModal} message={`Issues tạo thành công: ${listIssueAlert.length}`} type="success" showIcon />
                <Alert onClick={showModal} style={{ marginTop: '10px' }} message={`Số issues bị lỗi: ${listIssueError.length}`} type="error" showIcon />
              </div>
            </Card>,
          </div>
        )
      }
      <Modal width={1000} title="Danh sách issue vừa tạo" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div className='d-flex list-issue-alert'>
          <Table columns={listIssueColumn} dataSource={listIssueAlert} />
          <Table columns={listIssueErrorColumn} dataSource={listIssueError} />
        </div>
      </Modal>
    </div>;
  };

  const steps = [
    {
      title: "Chuẩn bị",
      content: <First />,
    },
    {
      title: "Upload",
      content: <Second />,
    },
    {
      title: "Dowload",
      content: <Last />,
    },
  ];

  const handleChangeType = (type) => {
    setChangeType(!changeType)
  }

  return (
    <div >
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          changeType ? (
            <Button disabled={dataBacklog.length === 0 && current === 1 && errorList.length === 0} style={{}} type="primary" onClick={() => next()}>
              Next
            </Button>
          ) : (
            <Button disabled={dataTemplace.length === 0 && current === 1} type="primary" onClick={() => next()}>
              Next
            </Button>
          )
        )}
        {current === 2 && dataTemplace.length > 0 && !changeType && (
          <Button type={'primary'} style={{ margin: "0 8px" }} onClick={() => backtopFirst()}>
            Create Issue
          </Button>
        )}
      </div>
    </div>
  )
}

export default UploadXlsx;