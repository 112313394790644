import React, { useEffect, useState } from "react";

 const UploadTemplace = (props) => {
  return (
    <form style={{display: 'flex', justifyContent: 'center'}}>
      <label className='btn-upload' htmlFor="uploadTemplace">Tải lên file excel</label>
      <input
        style={{ display: 'none' }}
        type="file"
        name="upload"
        id="uploadTemplace"
        onChange={props.readUploadFileImportTemplace}
      />
    </form>
  )
}

export default UploadTemplace;