import React  from 'react'


function Projects (props) {
    return (<div>
        project page
    </div>)
}


export default Projects